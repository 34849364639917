import {
  api,
  createCrudService,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/controltower/takeUpGin', api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (_component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.region.onValueChange = (_changedValue, fields) => {
    const idValue = fields.id.value
    fields.region.disabled = true
    if (idValue == null || idValue < 1) {
      fields.region.hidden = true
    }
  }
  fieldsById.code.onValueChange = (_changedValue, fields) => {
    const idValue = fields.id.value
    if (idValue != null || idValue > 0) {
      if (fields.isGinPreview?.value) {
        fields.code.disabled = false
        fieldsById.code.editable = true
      }
    }
  }
  helpers.defineTakeUpLocality(
    ctx.cityByState,
    fieldsById,
    'state',
    'city',
    _component
  )
}

export default service
